import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import config from "./config.module";
import metadata from "./metadata.module";
import order from "./order.module";

export default new Vuex.Store({
  modules: {
    config,
    metadata,
    order
  }
});
