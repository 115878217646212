import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "home",
            component: () => import("@/view/pages/Dashboard")
        },
        {
            path: "/thanks",
            name: "thanks",
            component: () => import("@/view/pages/Thanks")
        },
        {
            path: "/agreement",
            name: "agreement",
            component: () => import("@/view/pages/Agreement")
        },
        {
            path: "/about",
            name: "about",
            component: () => import("@/view/pages/About")
        },
        {
            path: "/vision",
            name: "vision",
            component: () => import("@/view/pages/Vision")
        },
        {
            path: "/story",
            name: "story",
            component: () => import("@/view/pages/Story")
        },
        {
            path: "/private_policy",
            name: "private_policy",
            component: () => import("@/view/pages/PrivatePolicy")
        },
        {
            path: "/terms",
            name: "terms",
            component: () => import("@/view/pages/Terms")
        },
        {
            path: "*",
            name: "404",
            component: () => import("@/view/pages/Dashboard")
        }
    ]
});
