import ApiService from "@/core/services/api.service";

// action types
export const CREATE_ORDER = "CREATE_ORDER";

// mutation types
export const SET_SERIAL_NUMBER = "SET_SERIAL_NUMBER";

const state = {
  serialNumber: null
};

const getters = {
  serialNumber(state) {
    return state.serialNumber;
  }
};

const actions = {
  [CREATE_ORDER](context, parameters) {
    return new Promise((resolve, reject) => {
      ApiService.post("order/create", parameters)
        .then(({ data }) => {
          context.commit(SET_SERIAL_NUMBER, data.data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  [SET_SERIAL_NUMBER](state, data) {
    state.serialNumber = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
