import ApiService from "@/core/services/api.service";

// action types
export const FETCH_GEOGRAPHIC = "FETCH_GEOGRAPHIC";

// mutation types
export const SET_GEOGRAPHIC = "SET_GEOGRAPHIC";

const state = {
  geographic: []
};

const getters = {
  geographic(state) {
    return state.geographic;
  }
};

const actions = {
  [FETCH_GEOGRAPHIC](context) {
    return new Promise(resolve => {
      ApiService.get("config/geographic").then(({ data }) => {
        context.commit(SET_GEOGRAPHIC, data.data);
        resolve(data);
      });
    });
  }
};

const mutations = {
  [SET_GEOGRAPHIC](state, error) {
    state.geographic = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
