import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
// import MockService from "@/core/mock/mock.service";

Vue.config.productionTip = false;

// Global 3rd party plugins
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;

// ClipboardJS
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/inline-svg";
import "@/core/plugins/treeselect";
import "@/core/plugins/carousel";
import "@mdi/font/css/materialdesignicons.css";

// API service init
ApiService.init();

// Remove this to disable mock API

// const shouldMock = process.env.VUE_APP_SHOULD_MOCK
// if (shouldMock === 1) {
//     MockService.init();
// }

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
